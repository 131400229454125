
/* eslint-disable @typescript-eslint/camelcase */
import {
  computed,
  ref,
  defineComponent,
  reactive,
  watch,
  nextTick,
  onMounted
} from "vue";
import SkillsDropdown from "@/components/clients/misc/RatesSkillsDropdown.vue";
import BranchesDropdown from "@/components/dropdown/BranchesDropdown.vue";
import CurrenciesDropdown from "@/components/dropdown/CurrenciesDropdown.vue";
import SkillsTable from "@/components/clients/misc/RatesDataTable.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import { useStore } from "vuex";
import Inputmask from "inputmask";
import Swal from "sweetalert2";

export default defineComponent({
  name: "rates-tab",
  components: {
    SkillsDropdown,
    BranchesDropdown,
    CurrenciesDropdown,
    LoadingSpinner,
    SkillsTable
  },
  setup() {
    const store = useStore();
    const loading = computed(() => store.getters["NewRatesModule/loading"]);
    const companyRate = computed(
      () => store.getters["NewRatesModule/company_rate"]
    );
    const client = computed(
      () => store.getters["ClientsModule/getFetchedSingle"]
    );
    const minimum_call = ref<number | string>("");
    const hasEnabledNightHours = ref<boolean>(false);
    const currency_id = ref<number | string>("");
    const query = reactive({
      skill_id: "" as number | string,
      branch_id: "" as number | string,
      company_id: client.value.id
    });
    const nightHours = reactive({
      night_start: "19:00" as string,
      night_end: "07:00" as string
    });
    onMounted(() => {
      if (client.value.default_branch_id != null) {
        query.branch_id = client.value.default_branch_id;
      }
    });
    function resetFilters() {
      nightHours.night_start = "19:00";
      nightHours.night_end = "07:00";
      hasEnabledNightHours.value = false;
      currency_id.value = "";
      minimum_call.value = "";
    }
    watch(
      query,
      () => {
        if (query.skill_id != "") {
          minimum_call.value = "";
          store.dispatch("NewRatesModule/fetch", query).then(() => {
            if (companyRate.value) {
              hasEnabledNightHours.value =
                companyRate.value.has_night_hours == 1 ? true : false;
              currency_id.value = companyRate.value.currency_id;
              if (
                companyRate.value.rates &&
                companyRate.value.rates.length > 0 &&
                query.skill_id != "frequent"
              ) {
                minimum_call.value = companyRate.value.rates[0].minimum_call
                  ? companyRate.value.rates[0].minimum_call
                  : "";
              }
              nightHours.night_start = companyRate.value.night_start;
              nightHours.night_end = companyRate.value.night_end;
            } else {
              resetFilters();
            }
          });
        } else {
          resetFilters();
        }
      },
      {
        deep: true
      }
    );

    watch(hasEnabledNightHours, async () => {
      await nextTick();
      if (hasEnabledNightHours.value) {
        Inputmask({
          regex: "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"
        }).mask("#night_hours_1");

        Inputmask({
          regex: "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"
        }).mask("#night_hours_2");
      }
    });
    function handleSave(data) {
      store
        .dispatch("NewRatesModule/store", {
          skills: data,
          has_night_hours: hasEnabledNightHours.value,
          minimum_call: minimum_call.value,
          currency_id: currency_id.value,
          ...query,
          ...nightHours
        })
        .then(() => {
          Swal.fire({
            position: "top-end",
            toast: true,
            icon: "success",
            title: `Record saved`,
            showConfirmButton: false,
            timer: 1000
          });
        });
    }
    function handleUpdate(data) {
      store
        .dispatch("NewRatesModule/update", {
          skills: data,
          has_night_hours: hasEnabledNightHours.value,
          currency_id: currency_id.value,
          minimum_call: minimum_call.value,
          ...query,
          ...nightHours
        })
        .then(() => {
          Swal.fire({
            position: "top-end",
            toast: true,
            icon: "success",
            title: `Record updated`,
            showConfirmButton: false,
            timer: 1000
          });
        });
    }
    return {
      query,
      handleSave,
      handleUpdate,
      minimum_call,
      client,
      hasEnabledNightHours,
      currency_id,
      nightHours,
      loading
    };
  }
});
