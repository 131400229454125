
/* eslint-disable @typescript-eslint/camelcase */
import { computed, defineComponent, PropType, ref, reactive } from "vue";
import { useStore } from "vuex";

type Skill = {
  id: number;
  name: string;
  order: number;
  parent_id: number;
  updated_at: string;
  created_at: string;
  worker_description: string;
  company_description: string;
};

export default defineComponent({
  /* eslint-disable @typescript-eslint/camelcase */
  name: "client-rate-data-table",
  props: {
    hasNightHours: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    skillID: {
      type: [Number, String] as PropType<number | string>,
      required: true
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const skills = computed(() => store.getters["NewRatesModule/skills"]);
    const bulkEditList = ref<number[]>([]);
    const bulkEdit = reactive({
      night_bonus: "",
      weekend_bonus: "",
      base_rate: "",
      minimum_call: ""
    });
    const storeInProgress = computed(
      () => store.getters["NewRatesModule/storeInProgress"]
    );
    const companyRate = computed(
      () => store.getters["NewRatesModule/company_rate"]
    );
    const tableKey = ref<number>(0);
    const hasRate = ref<boolean>(false);
    const model = {};
    const handleBulkEditList = skill_id => {
      if (bulkEditList.value.includes(skill_id)) {
        bulkEditList.value = bulkEditList.value.filter(e => e != skill_id);
      } else {
        bulkEditList.value.push(skill_id);
      }
    };
    function buildModel(skills: Skill[]) {
      hasRate.value = companyRate.value.rates ? true : false;
      for (let i = 0; i < skills.length; i++) {
        let standard_price = 0;
        let weekend_price = 0;
        let night_price = 0;
        let existingRateID = null;
        if (
          companyRate.value &&
          companyRate.value.rates &&
          companyRate.value.rates.length &&
          companyRate.value.rates.some(rate => rate.skill_id == skills[i].id)
        ) {
          const existingRate = companyRate.value.rates.find(
            rate => rate.skill_id == skills[i].id
          );
          standard_price = existingRate.standard_price;
          weekend_price = existingRate.weekend_price;
          night_price = existingRate.night_price;
          existingRateID = existingRate.id;
        }
        model[skills[i].id] = {
          skill_id: skills[i].id,
          standard_price: standard_price,
          weekend_price: weekend_price,
          existing_rate_id: existingRateID,
          night_price: night_price
        };
      }
    }
    const subSkills = computed((): Skill[] => {
      const skill = skills.value.find(
        (skill: Skill) => skill.id == props.skillID
      );
      if (skill && skill.children) {
        buildModel(skill.children);
        return skill.children;
      }
      return [];
    });
    const handleSelectAllRows = event => {
      if (event) {
        bulkEditList.value = [];
        Object.keys(model).forEach(key => {
          bulkEditList.value.push(parseInt(key));
        });
      } else {
        bulkEditList.value = [];
      }
      tableKey.value++;
    };
    const applyBulkEdit = () => {
      bulkEditList.value.forEach(skill => {
        model[skill].standard_price =
          bulkEdit.base_rate != ""
            ? bulkEdit.base_rate
            : model[skill].standard_price;
        model[skill].night_price =
          bulkEdit.night_bonus != ""
            ? bulkEdit.night_bonus
            : model[skill].night_price;
        model[skill].weekend_price =
          bulkEdit.weekend_bonus != ""
            ? bulkEdit.weekend_bonus
            : model[skill].weekend_price;
      });
      tableKey.value++;
    };
    function handleSave() {
      if (hasRate.value) emit("updateEvent", model);
      else emit("saveEvent", model);
    }

    return {
      subSkills,
      handleSelectAllRows,
      bulkEdit,
      bulkEditList,
      storeInProgress,
      tableKey,
      model,
      applyBulkEdit,
      handleBulkEditList,
      handleSave
    };
  }
});
