<template>
  <el-select
    v-model="value"
    :disabled="isLoading"
    clearable
    size="large"
    filterable
    placeholder="Select"
    class="w-100"
  >
    <el-option
      v-for="item in skills"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    >
    </el-option>
  </el-select>
</template>
<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "rates-skills-dropdown",
  props: {
    companyID: Number
  },
  data() {
    return {
      value: null,
      isLoading: false
    };
  },
  methods: {
    ...mapActions("NewRatesModule", ["fetchSkills"])
  },
  mounted() {
    this.isLoading = true;
    this.fetchSkills(this.companyID)
      .then(() => {
        this.isLoading = false;
        if (this.skills && this.skills.length > 0 && this.skills[0].id == "freq_1") {
          this.$emit("hasFrequentSkills", "freq_1");
        }
      })
      .catch(() => {
        this.isLoading = false;
      });
  },
  computed: {
    ...mapGetters("NewRatesModule", ["skills"])
  }
});
</script>
