<template>
  <div class="row gy-5 g-xl-8">
    <ViewSingle widget-classes="card-xxl-stretch mb-5 mb-xl-8"></ViewSingle>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import ViewSingle from "@/components/clients/ViewSingle.vue"
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "view-single-client",
  components: {
    ViewSingle
  },
  mounted() {
    setCurrentPageTitle("View client");
  },
  computed: {
    ...mapGetters({ currentUser: "currentUser" })
  }
});
</script>
