<template>
  <div class="tab-pane fade active show" role="tabpanel" v-if="client">
    <div class="card card-flush mb-6 mb-xl-9">
      <div class="card-header mt-6">
        <div class="card-title flex-column">
          <h2 class="mb-1">Overview</h2>
        </div>
      </div>
      <div class="card-body row">
        <div class="table-responsive">
          <table class="table align-middle table-row-dashed">
            <tbody class="fs-7 fw-bold text-gray-600">
              <tr>
                <td>Location</td>
                <td>{{ client.country.title }}, {{ client.city }}</td>
              </tr>
              <tr v-if="client.address">
                <td>Address</td>
                <td>{{ client.address }}</td>
              </tr>
              <tr v-if="client.tax_id">
                <td>TAX ID</td>
                <td>{{ client.tax_id }}</td>
              </tr>
              <tr v-if="client.province">
                <td>Province</td>
                <td>{{ client.province }}</td>
              </tr>
              <tr v-if="client.zip">
                <td>ZIP</td>
                <td>{{ client.zip }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "view-single-client-overview-tab",
  props: {
    client: Object
  }
});
</script>
