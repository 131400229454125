<template>
  <div class="tab-pane fade active show" role="tabpanel" v-if="client">
    <div class="card card-flush mb-6 mb-xl-9">
      <div class="card-header mt-6">
        <div class="card-title flex-column">
          <h2 class="mb-1">Details</h2>
        </div>
      </div>
      <div class="card-body row">
        <div class="table-responsive">
          <table class="table align-middle table-row-dashed">
            <tbody class="fs-7 fw-bold text-gray-600">
              <tr v-if="client.default_branch">
                <td>Default branch</td>
                <td>{{ client.default_branch.name }}</td>
              </tr>
              <tr v-if="client.default_country">
                <td>Default country</td>
                <td>{{ client.default_country.title }}</td>
              </tr>
              <tr v-if="client.default_city">
                <td>Default city</td>
                <td>{{ client.default_city }}</td>
              </tr>
            </tbody>
          </table>
          <div class="fs-7 fw-bold text-gray-600 mt-5" v-if="client.details">
            <span>Details: </span>
            <span>{{ client.details }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "view-single-client-details-tab",
  props: {
    client: Object
  }
});
</script>
