<template>
  <div class="tab-pane fade active show">
    <div class="card card-flush mb-6 mb-xl-9">
      <div class="card-header mt-6">
        <div class="card-title flex-column">
          <h2 class="mb-1">Users</h2>
        </div>
      </div>
      <div class="card-body p-9 pt-4">
        <LoadingSpinner v-if="loading"></LoadingSpinner>
        <div class="table-responsive" v-else>
          <table
            class="
            table table-row-dashed table-row-gray-300
            align-middle
            gs-0
            gy-4
          "
          >
            <thead>
              <tr class="fw-bolder text-muted">
                <th class="min-w-150px">User</th>
                <th class="min-w-140px">Phone</th>
                <th class="min-w-120px">Email</th>
                <th class="min-w-100px text-end">Invited By</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in data" :key="user.id">
                <td>
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-45px me-5">
                      <Img :src="user.photo_url" alt="" />
                    </div>
                    <div class="d-flex justify-content-start flex-column">
                      <router-link
                        :to="'/users/' + user.id + '/personal'"
                        class="text-dark fw-bolder text-hover-primary fs-6"
                      >
                        <span
                          v-if="user.status == 'inactive'"
                          class="badge badge-danger"
                          >Inactive</span
                        >
                        {{ user.name }}</router-link
                      ><span
                        class="text-muted fw-bold text-muted d-block fs-7"
                        v-for="role in user.role"
                        :key="role.id"
                      >
                        {{ role.title }}
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <PhoneLink
                    :phone="user.phone"
                    css-classes="text-dark fw-bolder text-hover-primary d-block fs-6"
                  ></PhoneLink>
                </td>
                <td>
                  <a
                    :href="'mailto:' + user.email"
                    class="text-dark fw-bolder text-hover-primary d-block fs-6"
                    >{{ user.email }}</a
                  >
                </td>
                <td>
                  <router-link
                    v-if="user.invited_by"
                    :to="`/users/${user.invited_by.id}/personal`"
                    class="text-dark fw-bolder text-hover-primary d-block fs-6 text-end"
                    >{{ user.invited_by.name }}</router-link
                  >
                  <span class="text-dark fw-bolder d-block fs-6 text-end" v-else
                    >N/A</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          :totalPages="pagination.last_page"
          :currentPage="pagination.current_page"
          @changeCurrentPage="handleChangePage"
        ></Pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import PhoneLink from "@/components/general/PhoneLink.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import { mapGetters, mapActions } from "vuex";

export default defineComponent({
  name: "view-single-client-users-tab",
  components: {
    LoadingSpinner,
    PhoneLink,
    Pagination
  },
  props: {
    data: Object,
    loading: Boolean
  },
  data: () => ({
    pagination: {}
  }),
  computed: {
    ...mapGetters("ClientsModule", ["getUsersTabPagination"])
  },
  methods: {
    ...mapActions("ClientsModule", ["fetchClientRelatedUsers"]),
    handleChangePage(pageNumber) {
      this.fetchClientRelatedUsers({
        id: this.$route.params.id,
        currentPage: pageNumber
      });
    }
  },
  updated() {
    this.pagination = this.getUsersTabPagination;
  }
});
</script>
