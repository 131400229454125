<template>
  <GenerateCrewRosterModal ref="crewRosterModal" />
  <LoadingSpiner v-if="loading"></LoadingSpiner>
  <div class="d-flex flex-column flex-lg-row" v-else>
    <div
      class="flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10"
      v-if="!['rates', 'preferredSkills'].includes(activeTab)"
    >
      <div class="card mb-5 mb-xl-8">
        <div class="card-body">
          <div class="d-flex flex-center flex-column py-5">
            <div class="symbol symbol-100px symbol-circle mb-7">
              <Img
                :src="getFetchedSingle.logo_url"
                v-if="getFetchedSingle.logo_url"
              />
            </div>
            <a
              href="#"
              class="fs-3 text-gray-800 text-hover-primary fw-bolder mb-3"
              >{{ getFetchedSingle.name }}</a
            >
          </div>
          <div class="d-flex flex-stack fs-4 py-3">
            <div
              class="fw-bolder rotate collapsible"
              data-bs-toggle="collapse"
              href="#client_general_tab"
              role="button"
              aria-expanded="false"
              aria-controls="client_general_tab"
            >
              General
              <span class="ms-2 rotate-180">
                <span class="svg-icon svg-icon-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                      fill="black"
                    ></path>
                  </svg>
                </span>
              </span>
            </div>
          </div>
          <div class="separator"></div>
          <div id="client_general_tab" class="collapse show">
            <div class="pb-5 fs-6">
              <div class="fw-bolder mt-5">Created at</div>
              <div class="text-gray-600">{{ getFetchedSingle.created_at }}</div>
              <div class="fw-bolder mt-5">Invoice email</div>
              <div class="text-gray-600">
                {{ getFetchedSingle.invoice_email }}
              </div>
              <div v-if="getFetchedSingle.phone">
                <div class="fw-bolder mt-5">Phone</div>
                <div class="text-gray-600">{{ getFetchedSingle.phone }}</div>
              </div>
              <div
                class="fw-bolder mt-5 mb-5"
                v-if="!!getFetchedSingle.key_account_managers"
              >
                Key account managers:
              </div>
              <div v-if="getFetchedSingle.key_account_managers">
                <UserBox
                  v-for="manager in getFetchedSingle.key_account_managers"
                  :key="manager.id"
                  :user="manager"
                ></UserBox>
              </div>
              <div
                class="fw-bolder mt-5 mb-5"
                v-if="getFetchedSingle.invited_by"
              >
                Invited by:
              </div>
              <UserBox
                v-if="getFetchedSingle.invited_by"
                :user="getFetchedSingle.invited_by"
              ></UserBox>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="flex-lg-row-fluid"
      :class="
        ['rates', 'preferredSkills'].includes(activeTab) ? '' : 'ms-lg-15'
      "
    >
      <!--begin:::Tabs-->
      <ul
        class="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8"
      >
        <li class="nav-item">
          <span
            class="nav-link text-active-primary pb-4 cursor-pointer"
            :class="activeTab == 'overview' ? 'active' : ''"
            @click="activeTab = 'overview'"
            >Overview</span
          >
        </li>
        <li class="nav-item">
          <span
            class="nav-link text-active-primary pb-4 cursor-pointer"
            :class="activeTab == 'details' ? 'active' : ''"
            @click="activeTab = 'details'"
            >Details</span
          >
        </li>

        <li class="nav-item">
          <span
            class="nav-link text-active-primary pb-4 cursor-pointer"
            :class="activeTab == 'users' ? 'active' : ''"
            @click="handleUsersTab"
            >Users</span
          >
        </li>

        <li class="nav-item" v-if="$can('can_manage_client_rates', 'all')">
          <span
            class="nav-link text-active-primary pb-4 cursor-pointer"
            :class="activeTab == 'rates' ? 'active' : ''"
            @click="activeTab = 'rates'"
            >Rates</span
          >
        </li>

        <li
          class="nav-item"
          v-if="$can('can_select_client_preferred_skills', 'all')"
        >
          <span
            class="nav-link text-active-primary pb-4 cursor-pointer"
            :class="activeTab == 'preferredSkills' ? 'active' : ''"
            @click="activeTab = 'preferredSkills'"
            >Preferred skills</span
          >
        </li>

        <li class="nav-item ms-auto d-none d-md-block">
          <div class="d-flex justify-content-end gap-3">
            <button @click="handleCrewRoster" class="btn btn-primary btn-sm">
              Crew Roster
            </button>
            <router-link
              v-if="$can('company_edit', 'all')"
              :to="'/clients/edit/' + getFetchedSingle.id"
              type="button"
              class="btn btn-sm btn-warning"
              >Edit</router-link
            >
            <button @click="handleBack" class="btn btn-primary btn-sm">
              Back
            </button>
          </div>
        </li>
      </ul>
      <OverviewTab
        :client="getFetchedSingle"
        v-if="activeTab == 'overview' && !loading"
      ></OverviewTab>
      <DetailsTab
        :client="getFetchedSingle"
        v-if="activeTab == 'details' && !loading"
      ></DetailsTab>
      <UsersTab
        v-if="activeTab == 'users'"
        :loading="isFetchingUsers && !loading"
        :data="getRelatedUsers"
      ></UsersTab>
      <RatesTab v-if="activeTab == 'rates'"></RatesTab>
      <PreferredSkillsTab v-if="activeTab == 'preferredSkills'" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import LoadingSpiner from "@/components/general/LoadingSpinner.vue";
import UserBox from "@/components/widgets/user/UserBox.vue";
import OverviewTab from "@/components/clients/tabs/Overview.vue";
import UsersTab from "@/components/clients/tabs/Users.vue";
import RatesTab from "@/components/clients/tabs/Rates.vue";
import DetailsTab from "@/components/clients/tabs/Details.vue";
import Img from "@/components/general/Img.vue";
import PreferredSkillsTab from "@/components/clients/tabs/PreferredSkills.vue";
import GenerateCrewRosterModal from "@/components/modals/GenerateCrewRosterModal.vue";

export default defineComponent({
  name: "view-single-client",
  components: {
    GenerateCrewRosterModal,
    PreferredSkillsTab,
    Img,
    LoadingSpiner,
    RatesTab,
    UserBox,
    OverviewTab,
    DetailsTab,
    UsersTab
  },
  data: () => ({
    activeTab: "overview"
  }),
  methods: {
    ...mapActions("ClientsModule", [
      "fetchSingle",
      "fetchClientRelatedUsers",
      "resetState"
    ]),
    ...mapActions("RosterModule", ["downloadRosterFile"]),
    handleCrewRoster() {
      this.$refs.crewRosterModal.toggleModal(true, this.getFetchedSingle.id);
    },
    handleBack() {
      this.$router.push("/clients");
    },
    handleUsersTab() {
      this.activeTab = "users";
      this.fetchClientRelatedUsers({
        id: this.$route.params.id,
        currentPage: 1
      });
    }
  },
  computed: {
    ...mapGetters("ClientsModule", [
      "getFetchedSingle",
      "loading",
      "isFetchingUsers",
      "getRelatedUsers"
    ])
  },
  created() {
    this.fetchSingle(this.$route.params.id);
  },
  beforeUnmount() {
    this.resetState();
  }
});
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
