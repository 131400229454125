
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch
} from "vue";
import { useStore } from "vuex";
import { fireToast } from "@/core/helpers/swal";
import item1 from "@/layout/header/partials/activity-timeline/Item1.vue";
export interface Option {
  key: number;
  label: string;
  initial: string;
}

export interface PreferredSkill {
  id: number;
  skill: string;
}

export interface Skill {
  id: number;
  name: string;
  children: Skill[];
}

export interface SkillTransformElement {
  id: number;
  name: string;
}

export default defineComponent({
  name: "preferred-skills-tab",
  setup: function() {
    const store = useStore();
    const loading = ref<boolean>(false);
    const showSaveButton = ref<boolean>(false);

    const client = computed(
      () => store.getters["ClientsModule/getFetchedSingle"]
    );
    const data = ref<Option[]>([]);
    const value = ref<number[]>([]);

    const skills = computed<SkillTransformElement[]>(
      (): SkillTransformElement[] => {
        const skillsList = store.getters["ListsModule/skills"] as Skill[];
        const transformElements = [] as SkillTransformElement[];

        for (let i = 0; i < skillsList.length; i++) {
          const parentSkill = skillsList[i] as Skill;
          parentSkill.children.forEach((subSkill: Skill) => {
            transformElements.push({
              id: subSkill.id,
              name: `${parentSkill.name} / ${subSkill.name}`
            });
          });
        }

        return transformElements;
      }
    );

    const preferredSkills = computed<PreferredSkill[]>(
      (): PreferredSkill[] => store.getters["ClientsModule/preferredSkills"]
    );

    function setLoading(state: boolean): void {
      loading.value = state;
    }

    function fetchPreferredSkills(): void {
      setLoading(true);
      value.value = [];
      store
        .dispatch("ClientsModule/fetchPreferredSkills")
        .then(() => {
          preferredSkills.value.forEach((el: PreferredSkill) => {
            value.value.push(el.id);
          });
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }

    function handleSave(event): void {
      const query = {
        skills: value.value,
        // eslint-disable-next-line @typescript-eslint/camelcase
        company_id: client.value.id
      };
      store
        .dispatch("ClientsModule/savePreferredSkills", query)
        .then(() => {
          fireToast("Preferred skills saved successfully", true);
        })
        .catch(err => {
          fireToast(err.message ? err.message : "An error has ocurred", false);
          value.value = value.value.filter(item => !event.includes(item));
        });
    }

    onUnmounted((): void => {
      store.dispatch("ClientsModule/clearPreferredSkills");
    });

    onMounted((): void => {
      fetchPreferredSkills();
      store.dispatch("ListsModule/fetchSkills").then(() => {
        skills.value.forEach((el: SkillTransformElement) => {
          data.value.push({
            label: el.name,
            key: el.id,
            initial: el.name
          });
        });
      });
    });

    function filterMethod(query: string, item: { initial: string }): boolean {
      const fullName = item.initial.toLowerCase().split("/");
      const parentSkill = fullName[0];
      const subSkill = fullName[1];
      return (
        parentSkill.trim().includes(query.toLowerCase()) ||
        subSkill.trim().includes(query.toLowerCase())
      );
    }

    return {
      client,
      handleSave,
      showSaveButton,
      loading,
      data,
      value,
      filterMethod
    };
  }
});
